.event-search-title {
  margin: 0; /* Remove default margin for alignment */
}

.logout-button {
  background-color: blue;
  border-color: blue;
}

.widget-26-job-starred .add {
    fill: gray; /* Example color when not favourited */
}

.widget-26-job-starred .remove {
    fill: gold; /* Example color when favourited */
}

.star {
  fill: none;
  stroke: #ddd;
  cursor: pointer;
  transition: fill 0.3s ease, stroke 0.3s ease;
}

.star.active svg {
  fill: orange;
  stroke: orange;
}

body{
  background:#dcdcdc;
  margin-top:20px;}

.widget-26 {
color: #3c4142;
font-weight: 400;
}

.widget-26 tr:first-child td {
border: 0;
}

.widget-26 .widget-26-job-emp-img img {
width: 35px;
height: 35px;
border-radius: 50%;
}

.widget-26 .widget-26-job-title {
min-width: 200px;
}

.widget-26 .widget-26-job-title a {
font-weight: 400;
font-size: 0.875rem;
color: #3c4142;
line-height: 1.5;
}

.widget-26 .widget-26-job-title a:hover {
color: #68CBD7;
text-decoration: none;
}

.widget-26 .widget-26-job-title .employer-name {
margin: 0;
line-height: 1.5;
font-weight: 400;
color: #3c4142;
font-size: 0.8125rem;
color: #3c4142;
}

.widget-26 .widget-26-job-title .employer-name:hover {
color: #68CBD7;
text-decoration: none;
}

.widget-26 .widget-26-job-title .time {
font-size: 12px;
font-weight: 400;
}

.widget-26 .widget-26-job-info {
min-width: 100px;
font-weight: 400;
}

.widget-26 .widget-26-job-info p {
line-height: 1.5;
color: #3c4142;
font-size: 0.8125rem;
}

.widget-26 .widget-26-job-info .location {
color: #3c4142;
}

.widget-26 .widget-26-job-salary {
min-width: 70px;
font-weight: 400;
color: #3c4142;
font-size: 0.8125rem;
}

.widget-26 .widget-26-job-category {
padding: .5rem;
display: inline-flex;
white-space: nowrap;
border-radius: 15px;
}

.widget-26 .widget-26-job-category .indicator {
width: 13px;
height: 13px;
margin-right: .5rem;
float: left;
border-radius: 50%;
}

.widget-26 .widget-26-job-category span {
font-size: 0.8125rem;
color: #3c4142;
font-weight: 600;
}

.widget-26 .widget-26-job-starred svg {
width: 20px;
height: 20px;
color: #fd8b2c;
}

.widget-26 .widget-26-job-starred svg.starred {
fill: #fd8b2c;
}
.bg-soft-base {
background-color: #e1f5f7;
}
.bg-soft-warning {
  background-color: #fff4e1;
}
.bg-soft-success {
  background-color: #d1f6f2;
}
.bg-soft-danger {
  background-color: #fedce0;
}
.bg-soft-info {
  background-color: #d7efff;
}


.search-form {
width: 80%;
margin: 0 auto;
margin-top: 1rem;
}

.search-form input {
height: 100%;
background: transparent;
border: 0;
display: block;
width: 100%;
padding: 1rem;
height: 100%;
font-size: 1rem;
}

.search-form select {
background: transparent;
border: 0;
padding: 1rem;
height: 100%;
font-size: 1rem;
}

.search-form select:focus {
border: 0;
}

.search-form button {
height: 100%;
width: 100%;
font-size: 1rem;
}

.search-form button svg {
width: 24px;
height: 24px;
}

.search-body {
margin-bottom: 1.5rem;
}

.search-body .search-filters .filter-list {
margin-bottom: 1.3rem;
}

.search-body .search-filters .filter-list .title {
color: #3c4142;
margin-bottom: 1rem;
}

.search-body .search-filters .filter-list .filter-text {
color: #727686;
}

.search-body .search-result .result-header {
margin-bottom: 2rem;
}

.search-body .search-result .result-header .records {
color: #3c4142;
}

.search-body .search-result .result-header .result-actions {
text-align: right;
display: flex;
align-items: center;
justify-content: space-between;
}

.search-body .search-result .result-header .result-actions .result-sorting {
display: flex;
align-items: center;
}

.search-body .search-result .result-header .result-actions .result-sorting span {
flex-shrink: 0;
font-size: 0.8125rem;
}

.search-body .search-result .result-header .result-actions .result-sorting select {
color: #68CBD7;
}

.search-body .search-result .result-header .result-actions .result-sorting select option {
color: #3c4142;
}

@media (min-width: 768px) and (max-width: 991.98px) {
.search-body .search-filters {
  display: flex;
}
.search-body .search-filters .filter-list {
  margin-right: 1rem;
}
}

.card-margin {
  margin-bottom: 1.875rem;
}

@media (min-width: 992px){
.col-lg-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
}

.card-margin {
  margin-bottom: 1.875rem;
}
.card {
  border: 0;
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -ms-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #e6e4e9;
  border-radius: 8px;
}

.tile-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Ensures tiles expand to fit available space */
  gap: 16px; /* Adds space between items */
}

.tile-item {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 250px; /* Ensures a minimum width for items */
}

.tile-item button {
  font-size: 16px;
  font-weight: bold;
}

.tile-item p {
  margin: 8px 0;
}

.suggestions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
}

.suggestion-item:hover,
.suggestion-item.active {
  background-color: #f0f0f0;
}

/* Ensure the dropdown is positioned below the input */
.dropdown-content {
  position: absolute;
  top: 100%; /* Start right below the input */
  left: 0;
  width: 100%;
  z-index: 1000; /* Increase z-index so it stays above the button */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* On small screens, adjust layout so button doesn't overlap the dropdown */
@media (max-width: 768px) {
  .search-button-wrapper {
    margin-top: 10px; /* Add margin to push the button below the dropdown */
  }

  .dropdown-content {
    position: absolute;
    top: 100%; /* Ensure dropdown is still positioned below the input */
    z-index: 1000; /* Ensure dropdown stays above all other elements */
  }
}
