.btn-redirect {
    display: inline-block;
    /* Allow for padding */
    font-weight: 500;
    /* Medium font weight */
    color: #fff;
    /* White text color */
    text-align: center;
    /* Center text */
    text-decoration: none;
    /* Remove underline */
    padding: 0.375rem 0.75rem;
    /* Padding around button */
    border: 1px solid transparent;
    /* Initial border */
    border-radius: 0.25rem;
    /* Rounded corners */
    background-color: #007bff;
    /* Primary button color */
    cursor: pointer;
    /* Pointer on hover */
}

.btn-redirect:hover {
    background-color: #0056b3;
    /* Darker shade on hover */
    border-color: #0056b3;
    /* Darker border on hover */
}

.btn-redirect:focus {
    outline: none;
    /* Remove focus outline */
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    /* Add a shadow on focus */
}

.redirect-button-container {
    padding: 10px;
}

.btn-delete {
    display: inline-block;
    /* Allow for padding */
    font-weight: 500;
    /* Medium font weight */
    color: #fff;
    /* White text color */
    text-align: center;
    /* Center text */
    text-decoration: none;
    /* Remove underline */
    padding: 0.375rem 0.75rem;
    /* Padding around button */
    border: 1px solid transparent;
    /* Initial border */
    border-radius: 0.25rem;
    /* Rounded corners */
    background-color: #cc3813;
    /* Primary button color */
    cursor: pointer;
    /* Pointer on hover */
}

.btn-delete:hover {
    background-color: #da1d0f;
    /* Darker shade on hover */
    border-color: #da1d0f;
    /* Darker border on hover */
}

.btn-delete:focus {
    outline: none;
    /* Remove focus outline */
    box-shadow: 0 0 0 0.2rem rgba(236, 32, 5, 0.25);
    /* Add a shadow on focus */
}

.redirect-button-container {
    padding: 10px;
}

.form-container {
    background-color: #e0f7fa;
    /* Light blue background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    /* Light shadow for depth */
    max-width: 500px;
    /* Adjust as needed */
    margin: 20px auto;
    /* Centers the container */
    border: 1px solid #b2ebf2;
    /* Optional border for a defined edge */
}