.carousel-container {
  padding: 30px;
  background: linear-gradient(135deg, #f8f9fa, #e9ecef);
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.carousel-indicators div {
  width: 12px;
  height: 12px;
  margin: 5px;
  background-color: #ddd;
  border-radius: 50%;
  cursor: pointer;
}

.carousel-indicators .active {
  background-color: #007bff;
}

.carousel-inner {
  border-radius: 15px;
}

.carousel-caption {
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}

.result {
  background: white;
  border-radius: 10px;
  padding: 15px;
  max-height: 400px;
  overflow-y: auto;
}

.event-item {
  padding: 10px;
  background: #f1f1f1;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: 8px;
}

.event-item.active {
  background: #007bff;
  color: white;
}

.event-item:hover {
  background: #e0e0e0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.carousel-item.active {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px; /* Set a fixed height for consistency */
  background-color: #f8f9fa; /* Light background to contrast the image */
  border-radius: 10px; /* Matches the border radius of the images */
  overflow: hidden;
  transition: opacity 0.6s ease-in-out; /* Smooth transition effect */
}
.carousel-item {
  display: none;
}

.carousel-item img {
  max-height: 100%; /* Ensure the image fits within the item height */
  max-width: 100%; /* Maintain aspect ratio */
  object-fit: cover; /* Ensures image covers the container without distortion */
  border-radius: 10px;
}