.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  background-color: #f8f8f8; /* Adjust color as needed */
}

/* Logo styling */
.header-container img {
  height: 150px; /* Adjust size as desired */
  width: auto;
  cursor: pointer;
}

.btn {
  display: inline-block; /* Allow for padding */
  font-weight: 500; /* Medium font weight */
  color: #fff; /* White text color */
  text-align: center; /* Center text */
  text-decoration: none; /* Remove underline */
  padding: 0.375rem 0.75rem; /* Padding around button */
  border: 1px solid transparent; /* Initial border */
  border-radius: 0.25rem; /* Rounded corners */
  cursor: pointer; /* Pointer on hover */
}

.btn-secondary {
  background-color: #6c757d; /* Secondary button color */
  border-color: #6c757d; /* Same color for border */
}

.btn-secondary:hover {
  background-color: #5a6268; /* Darker shade on hover */
  border-color: #545b62; /* Darker border on hover */
}

.dropdown {
  position: relative; /* Required for dropdown positioning */
}

.dropdown-toggle {
  background-color: transparent; /* No background */
  border: none; /* No border */
  padding: 0; /* Remove padding */
  color: #6c757d; /* Button text color */
  font-size: 16px; /* Font size */
}

.dropdown-menu {
  position: absolute; /* Position dropdown relative to parent */
  top: 100%; /* Align to the bottom of the button */
  left: 0; /* Align to the left of the parent */
  z-index: 1000; /* Ensure dropdown appears above other content */
  display: none; /* Initially hide the dropdown */
  flex-direction: column; /* Stack items vertically */
  background-color: #ffffff; /* White background for dropdown */
  border: 1px solid #dee2e6; /* Border around dropdown */
  border-radius: 0.25rem; /* Rounded corners */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); /* Shadow effect */
}

.dropdown-menu.show {
  display: flex; /* Show dropdown when active */
}

.dropdown-item {
  padding: 0.5rem 1rem; /* Padding for dropdown items */
  color: #212529; /* Dark text color */
  text-decoration: none; /* No underline */
}

.dropdown-item:hover {
  background-color: #f8f9fa; /* Light background on hover */
}
