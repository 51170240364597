.map-container {
    width: '50%';
    margin: '0 auto';
    padding: '100px';
    border: '1px solid #ccc';
    border-radius: '100px';
    background-color: '#f5f5f5';
    display: flex;
    flex-direction: column;
    align-items: center;
}

.map-wrapper {
    width: 100%;
    max-width: 800px;
    /* Adjust the max width for larger screens */
    margin-bottom: 20px;
    /* Space between map and graphs */
}

.graphs-wrapper {
    width: 100%;
    max-width: 800px;
}

@media (min-width: 768px) {
    .map-container {
        /* On larger screens, you can offset the entire container */
        align-items: flex-start;
        /* Align to the left (or use 'center' if preferred) */
        margin-left: 500px;
    }
}

@media (max-width: 767px) {
    .map-container {
        /* Center content for mobile screens */
        align-items: center;
    }
}

.map {
    width: 100%;
    height: 400px;
}

.legend {
    position: relative;
    /* Or static */
    top: 10px;
    /* Adjust top position */
    left: 10px;
    /* Adjust left position */
}

@media (max-width: 600px) {
    .legend {
        position: static;
        /* Change to static */
        margin: 10px;
        /* Add some margin */
        width: 100%;
        /* Make it full width on smaller screens */
    }
}

.legend ul {
    list-style-type: none;
    padding: 0;
}

.legend li {
    margin-bottom: 8px;
    cursor: pointer;
    transition: opacity 0.2s;
}

.legend li:hover {
    opacity: 0.8;
}

.icon-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.icon-container::after {
    content: attr(data-tooltip);
    /* Tooltip text from data attribute */
    position: absolute;
    bottom: 100%;
    /* Position above the icon */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    font-size: 12px;
    transition: opacity 0.2s ease, visibility 0.2s ease;
    pointer-events: none;
    /* Prevent tooltip from interfering with hover */
}

.icon-container:hover::after {
    opacity: 1;
    visibility: visible;
}